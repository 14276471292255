import {
  Language,
  ConditionId,
  UserId,
  UserType,
  BaseUser,
  Condition as ConditionBase,
  ScoringSystemsFirstResult,
  Perspective,
  AssigmentSource,
  Pathology,
  BodySite
} from '@legit.health/ui';
import PatientConditionNotFound from '@/shared/classes/PatientConditionNotFound';
import PrivateInsuranceProvider from '@/shared/interfaces/PrivateInsuranceProvider';
import Practitioner from './Practitioner';

export class Condition extends ConditionBase {
  constructor(
    readonly id: ConditionId,
    readonly bodySite: BodySite,
    readonly pathology: Pathology,
    readonly practitioner: UserId,
    readonly frequency: number,
    readonly isTracked: boolean,
    readonly assigmentSource: AssigmentSource,
    readonly firstImage: string | null,
    readonly firstImageThumbnail: string | null,
    readonly createdAt: string,
    readonly firstImageOfPerspective: Record<Perspective, string>,
    readonly scoringSystemsFirstResults: ScoringSystemsFirstResult[],
    readonly canBetracked: boolean
  ) {
    super(
      id,
      bodySite,
      pathology,
      practitioner,
      frequency,
      isTracked,
      assigmentSource,
      firstImage,
      firstImageThumbnail,
      createdAt,
      firstImageOfPerspective
    );
  }
}

export default class Patient implements BaseUser {
  constructor(
    readonly id: UserId,
    readonly name: string,
    readonly surname: string,
    readonly secondSurname: string | null,
    readonly gender: string | null,
    readonly locale: Language,
    readonly fullname: string,
    readonly country: string | null,
    readonly email: string | null,
    readonly image: string | null,
    readonly imageThumbnail: string | null,
    readonly is2faEnabled: boolean,
    readonly hasCompletedTutorial: boolean,
    readonly enableExperimentalFeatures: boolean,
    readonly lastLogin: string | null,
    readonly allowsReceiveEmails: boolean,
    readonly type: UserType,
    readonly birthDate: string | null,
    readonly telephone: string | null,
    readonly emergencyContact: string | null,
    readonly address: string | null,
    readonly languages: string | null,
    readonly careGaps: string | null,
    readonly socialDeterminants: string | null,
    readonly skinType: string | null,
    readonly riskFactors: string | null,
    readonly allergies: string | null,
    readonly weight: string | null,
    readonly height: string | null,
    readonly nhc: string | null,
    readonly idCard: string | null,
    readonly healthCenter: string | null,
    readonly healthCardNumber: string | null,
    readonly privateInsuranceProvider: PrivateInsuranceProvider,
    readonly habits: string | null,
    readonly conditions: Condition[],
    readonly practitioner: Practitioner,
    readonly referredToPractitioners: UserId[],
    readonly canViewResultsOfDiagnosticReports: boolean,
    readonly isAllowedToUploadPhotos: boolean
  ) {}

  getAnalyzableConditions() {
    return this.conditions.filter((c) => c.canBetracked);
  }

  findCondition(this: Patient, conditionId: ConditionId) {
    return this.conditions.find((condition) => condition.id === conditionId);
  }

  getCondition(this: Patient, conditionId: ConditionId) {
    const condition = this.conditions.find((condition) => condition.id === conditionId);
    if (!condition) {
      throw new PatientConditionNotFound(
        `Patient Pathology with id <${conditionId}> not found`,
        conditionId
      );
    }
    return condition;
  }
}
