import {ReactNode, JSXElementConstructor, ReactElement} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Fade,
  IconButton,
  Typography,
  DialogProps
} from '@mui/material';
import {TransitionProps} from '@mui/material/transitions/transition';

function DialogTitle(
  props: MuiDialogTitleProps & {
    onClose: () => void;
  }
) {
  const {children, onClose, ...other} = props;
  return (
    <MuiDialogTitle
      sx={{
        margin: 0,
        padding: 2
      }}
      {...other}
    >
      <Typography
        variant="inherit"
        sx={{
          fontWeight: 'bold'
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 1,
            top: 8,
            color: 'grey.500'
          }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

export default function Modal({
  id,
  children,
  fullScreen = false,
  fullWidth = false,
  isOpened,
  onClose,
  size = 'md',
  Transition = Fade,
  title,
  disableEscapeKeyDown = false,
  disableEnforceFocus = false,
  disablePortal = false
}: {
  id?: string;
  children?: ReactNode;
  fullScreen?: boolean;
  fullWidth?: boolean;
  isOpened: boolean;
  onClose: () => void;
  size?: DialogProps['maxWidth'];
  Transition?: JSXElementConstructor<TransitionProps & {children: ReactElement<any, any>}>;
  title?: string;
  disableEscapeKeyDown?: boolean;
  disableEnforceFocus?: boolean;
  disablePortal?: boolean;
}) {
  return (
    <Dialog
      id={id}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      TransitionComponent={Transition}
      open={isOpened}
      scroll="body"
      maxWidth={size}
      onClose={onClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableEnforceFocus={disableEnforceFocus}
      disablePortal={disablePortal}
    >
      {!fullScreen && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      {fullScreen ? (
        children
      ) : (
        <DialogContent dividers sx={{border: 0, p: 2}}>
          {children}
        </DialogContent>
      )}
    </Dialog>
  );
}
